import React, { useState } from 'react';
//import Markdown from 'react-markdown';
import { MathJaxContext,MathJax } from 'better-react-mathjax';

function StudyMode({onFinish, onUpdateStats, collections, collectionId }) {
  const [index, setIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const flashcards = collections[collectionId]?.flashcards || [];
  console.log(flashcards);

  const currentCard = flashcards[index];

  function handleNext(response) {
    onUpdateStats(currentCard.id, response);
    setShowAnswer(false);
    setIndex(prev => prev + 1);
  }

  if (!currentCard) {
    return (
      <div className="text-center">
        <p>No more flashcards in this collection.</p>
        <button 
          onClick={onFinish} 
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
        >
          Finish
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-8 p-4 border rounded shadow">
      <div className="text-center mb-4">
      <MathJaxContext>
        <div className="text-xl font-semibold">
        
        <MathJax>{currentCard.front}</MathJax>
        </div>
        <div className="pt-8 text-md font-semibold">
          {showAnswer ? (
            <>
              Answer: 
                  <MathJax>{currentCard.back}</MathJax>
                  
            </>
          ) : ''}
        </div>
        </MathJaxContext>
      </div>

      <div className="flex justify-center space-x-4 mb-4">
        {!showAnswer && (
          <button
            className="bg-gray-200 py-1 px-3 rounded"
            onClick={() => setShowAnswer(true)}
          >
            Reveal
          </button>
        )}
      </div>

      {showAnswer && (
        <div className="flex flex-col space-y-2">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded"
            onClick={() => handleNext('knows')}
          >
            I know it!
          </button>
          <button
            className="bg-yellow-500 text-white py-2 px-4 rounded"
            onClick={() => handleNext('slow')}
          >
            Slow Recall
          </button>
          <button
            className="bg-red-500 text-white py-2 px-4 rounded"
            onClick={() => handleNext('dontKnow')}
          >
            Don&apos;t Know
          </button>
        </div>
      )}
    </div>
  );
}

export default StudyMode;