import React, { useState } from 'react';

function CreateCollection({ onSubmit, onCancel }) {
  const [title, setTitle] = useState('');
  const [cardsText, setCardsText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Parse the cards text into flashcard objects
    const flashcards = cardsText
      .split('\n')
      .filter(line => line.includes('>>'))
      .map((line, index) => {
        const [front, back] = line.split('>>').map(str => str.trim());
        return {
          id: `new-card-${index}`,
          front,
          back,
          stats: { knowsCount: 0, slowCount: 0, dontKnowCount: 0 }
        };
      });

    onSubmit({
      id: `collection-${Date.now()}`,
      title,
      flashcards
    });
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Create New Collection</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Collection Name:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        
        <div className="mb-4">
          <label className="block mb-2">Cards (one per line, format: Front >> Back):</label>
          <textarea
            value={cardsText}
            onChange={(e) => setCardsText(e.target.value)}
            className="w-full p-2 border rounded h-64 font-mono"
            placeholder="Hello >> Hola&#10;Goodbye >> Adiós"
            required
          />
        </div>

        <div className="flex gap-2">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Create Collection
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateCollection;