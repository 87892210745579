import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import CollectionView from './components/CollectionView';
import StudyMode from './components/StudyMode';
import CreateCollection from './components/CreateCollection';


// Mock local data or consider a localStorage service
const DEFAULT_DATA = {
  collections: {},
  stats: {} // Add stats object to store attempt history and feedback
};

const StudyModeWrapper = ({collections, handleUpdateStats, navigate}) => {
  const { collectionId } = useParams();
    return (
      <StudyMode 
      collections={collections}
      onUpdateStats={handleUpdateStats}
      collectionId={collectionId}
      onFinish={() => navigate(`/collections/${collectionId}`)}
    />
  );
}
const CollectionWrapper = ({collections, setData, navigate}) => {
  const { collectionId } = useParams();
  //console.log("my", collections);
  return (
    <CollectionView
      collections={collections}
      collectionId={collectionId}
      deleteCollection={() => {
        setData(prev => ({
          ...prev,
          collections: Object.fromEntries(
            Object.entries(prev.collections)
              .filter(([id]) => id !== collectionId)
          )
        }));
        navigate('/');
      }}
      onStudy={() => navigate(`/collections/${collectionId}/study`)}
      addCard={({collectionId, cardId, front, back}) => {
        setData(prev => ({
          ...prev,
          collections: {
            ...prev.collections,
            [collectionId]: {
              ...prev.collections[collectionId],
              flashcards: [
                ...prev.collections[collectionId].flashcards,
                {
                  id: cardId,
                  front,
                  back
                }
              ]
            }
          }
        }))
      }}
      onEditCard={({collectionId, cardId, front, back, delete: isDelete}) => {
        setData(prev => ({
          ...prev,
          collections: {
            ...prev.collections,
            [collectionId]: {
              ...prev.collections[collectionId],
              flashcards: isDelete
                ? prev.collections[collectionId].flashcards.filter(card => card.id !== cardId)
                : prev.collections[collectionId].flashcards.map(card =>
                    card.id === cardId ? {
                      ...card,
                      front: front || card.front,
                      back: back || card.back
                    } : card
                  )
            }
          }
        }))
      }}
    />
  );
};

function App() {
  const navigate = useNavigate();
  
  function handleCreateCollection(newCollection) {
    setData(prev => ({
      ...prev,
      collections: {
        ...prev.collections,
        [newCollection.id]: newCollection
      },
      stats: {
        ...prev.stats,
        [newCollection.id]: {} // Initialize stats for new collection
      }
    }));
  }

  const [data, setData] = useState(() => {
    const stored = localStorage.getItem('flashAppData');
    return stored ? JSON.parse(stored) : DEFAULT_DATA;
  });
  
  console.log(data);

  useEffect(() => {
    localStorage.setItem('flashAppData', JSON.stringify(data));
  }, [data]);


  useEffect(() => {
    const stored = localStorage.getItem('flashAppData');
    if (stored) setData(JSON.parse(stored));
  }, []);

  useEffect(() => {
    localStorage.setItem('flashAppData', JSON.stringify(data));
  }, [data]);



  return (
    <div className="w-full flex-col h-screen">
      <Navbar />
      <div className="flex flex-1">
        {/* On mobile with no collections, show only main content */}
        {data.collections.length === 0 ? (
          <main className="flex-1 p-4 md:flex">
            <Routes>
              <Route path="/" element={
                <div className="text-center mt-8 max-w-2xl mx-auto">
                    <h2 className="text-4xl font-bold text-gray-800 mb-4">Welcome to deja.app</h2>
                    <p className="text-lg text-gray-600 mb-6">Master any subject with our powerful spaced repetition system</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                  <div className="p-6 bg-white rounded-lg shadow-md">
                    <div className="text-blue-500 text-xl mb-2">📚 Create Collections</div>
                    <p className="text-gray-600">Organize your study materials into focused collections</p>
                  </div>
                  <div className="p-6 bg-white rounded-lg shadow-md">
                    <div className="text-blue-500 text-xl mb-2">🎯 Track Progress</div>
                    <p className="text-gray-600">Monitor your learning with detailed statistics</p>
                  </div>
                </div>
                <button
                  onClick={() => navigate('/collections/new')}
                  className="mt-4 px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Create Your First Collection
                </button>
              </div>
              }/>
              <Route 
                path="/collections/new" 
                element={
                  <div className="mx-auto w-3/6 justify-center">
                    <CreateCollection
                      onSubmit={handleCreateCollection}
                      onCancel={() => navigate("/")}
                    />
                  </div>
                } 
              />
            </Routes>
          </main>
        ) : (
          <>
            {/* On mobile with collections, show only sidebar */}
            <div className="w-full md:w-64">
              <Sidebar 
                collections={data.collections} 
                onCreateNew={() => navigate('/collections/new')}
              />
            </div>
            {/* Hide main content on mobile when collections exist, show on desktop */}
            <main className="hidden md:block flex-1 p-4">
              <Routes>
                <Route path="/" element={<Navigate to="/collections" replace />} />
                <Route 
                  path="/collections/new" 
                  element={
                    <CreateCollection
                      onSubmit={handleCreateCollection}
                      onCancel={() => navigate("/")}
                    />
                  } 
                />
                <Route 
                  path="/collections/:collectionId/study" 
                  element={
                    <StudyModeWrapper collections={data.collections} handleUpdateStats={() => {}} navigate={navigate} />
                  } 
                />
                <Route 
                  path="/collections/:collectionId" 
                  element={<CollectionWrapper collections={data.collections} setData={setData} navigate={navigate} />}
                />
                <Route 
                  path="/collections" 
                  element={
                    <div className="text-center mt-8 max-w-2xl mx-auto">
                      <h2 className="text-4xl font-bold text-gray-800 mb-4">Welcome to deja.app</h2>
                      <p className="text-lg text-gray-600 mb-6">Master any subject with our powerful spaced repetition system</p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="p-6 bg-white rounded-lg shadow-md">
                          <div className="text-blue-500 text-xl mb-2">📚 Create Collections</div>
                          <p className="text-gray-600">Organize your study materials into focused collections</p>
                        </div>
                        <div className="p-6 bg-white rounded-lg shadow-md">
                          <div className="text-blue-500 text-xl mb-2">🎯 Track Progress</div>
                          <p className="text-gray-600">Monitor your learning with detailed statistics</p>
                        </div>
                      </div>
                      <p className="text-gray-500 mt-4">Select a collection from the sidebar to begin studying</p>
                    </div>
                  } 
                />
              </Routes>
            </main>
          </>
        )}
      </div>
    </div>
  );
}

export default App;