import React from 'react';
import { useState } from 'react';
import { MathJaxContext,MathJax } from 'better-react-mathjax';

function CollectionView({ collections, collectionId, onEditCard, onStudy, addCard, deleteCollection}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input, setInput] = useState('');
  const collection = collections[collectionId];
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingBack, setIsEditingBack] = useState(false);
  if (!collection) {
    return (
      <div className="text-center mt-8 max-w-2xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome to deja</h2>
        <p className="text-lg text-gray-600 mb-6">Master any subject with our powerful spaced repetition system</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <div className="text-blue-500 text-xl mb-2">📚 Create Collections</div>
            <p className="text-gray-600">Organize your study materials into focused collections</p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <div className="text-blue-500 text-xl mb-2">🎯 Track Progress</div>
            <p className="text-gray-600">Monitor your learning with detailed statistics</p>
          </div>
        </div>
        <p className="text-gray-500">Select a collection from the sidebar to begin studying</p>
      </div>
    );
  }

  return (
    <div>
       {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg">
                <h3 className="text-lg font-bold mb-4">Add New Flashcards</h3>
                <p className="text-sm text-gray-600 mb-2">Enter your flashcards (one per line):</p>
                <p className="text-sm text-gray-600 mb-4">Format: Front {'>'}{'>'}  Back</p>
                <textarea
                  className="w-full h-48 p-2 border rounded font-mono text-sm mb-4"
                  placeholder="Hello >> Hola&#10;Goodbye >> Adiós" 
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <div className="flex justify-end gap-2">
                  <button
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                    onClick={() => {
                      setIsModalOpen(false);
                      setInput('');
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => {
                      if (input) {
                        const cards = input.split('\n')
                          .map(line => line.split('>>').map(s => s.trim()))
                          .filter(parts => parts.length === 2)
                          .map(([front, back]) => ({
                            collectionId: collectionId,
                            cardId: (Date.now() * 1000 + Math.floor(Math.random() * 1000)).toString(),
                            front,
                            back
                          }));
                          //console.log("Input", cards);
                          cards.forEach(card => addCard(card));
                        setInput('');
                        setIsModalOpen(false);
                      }
                    }}
                  >
                    Add Cards
                  </button>
                </div>
              </div>
            </div>
          )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="mx-auto text-center text-4xl font-bold">{collection.title}</h2>
        <div className="flex gap-2">
          <button 
            className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
            onClick={onStudy}
          >
            Start Study
          </button>
          <button
            className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
            onClick={deleteCollection}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <button 
        onClick={() => {

          setIsModalOpen(true);
          
        }}
        className="mb-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 flex items-center gap-2"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        Add Cards
      </button>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {collection.flashcards.map(card => (
          <div 
            key={card.id} 
            className="rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200 relative"
          >
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-red-500 transition-colors duration-200"
              onClick={() => onEditCard({collectionId: collectionId, cardId: card.id, delete: true})}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-20" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
            {isEditing ? (
              <textarea
                className="bg-transparent font-semibold text-lg mb-2 w-full border-b border-transparent hover:border-gray-300 focus:border-blue-500 focus:outline-none text-center"
                defaultValue={card.front}
                onBlur={(e) => {
                  onEditCard({collectionId: collectionId, cardId: card.id, front: e.target.value});
                  setIsEditing(false);
                }}
              />
            ) : (
              <div onClick={() => setIsEditing(true)}>
                <MathJaxContext>
                  <div className="pb-4 mx-auto flex justify-center"><MathJax>{card.front}</MathJax></div>
                </MathJaxContext>
              </div>
            )}
            {isEditingBack ? (
              <textarea
                className="bg-transparent text-gray-600 mb-4 w-full border-b border-transparent hover:border-gray-300 focus:border-blue-500 focus:outline-none" 
                defaultValue={card.back}
                onBlur={(e) => {
                  onEditCard({collectionId: collectionId, cardId: card.id, back: e.target.value});
                  setIsEditingBack(false);
                }}
              />
            ) : (
              <div onClick={() => setIsEditingBack(true)}>
                <MathJaxContext>
                  <MathJax>{card.back}</MathJax>
                </MathJaxContext>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollectionView;