import React from 'react';

function Navbar() {
  return (
    <nav className="w-full flex items-center justify-between px-4 py-2 bg-white">
      <a href='/'><h1 className="text-2xl font-bold text-center pl-20">deja</h1></a>
    </nav>
  );
}

export default Navbar;