import React from 'react';
import { Link } from 'react-router-dom';  // Add this import

function Sidebar({ collections, onCreateNew }) {
  return (
    <aside className="bg-gray-50 p-4 border-r">
      <h2 className="text-md font-semibold mb-2 text-center">Collections</h2>
      <ul className="text-center">
        {Object.entries(collections).map(([id, col]) => (
          <li key={id}>
            <Link
              to={`/collections/${id}`}
              className="block mb-2 cursor-pointer p-2 hover:bg-gray-200 rounded"
            >
              {col.title}
            </Link>
          </li>
        ))}
      </ul>
      <button
        onClick={onCreateNew}
        className="w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        + New
      </button>
    </aside>
  );
}

export default Sidebar;